import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, image, children }) => {
  const socialImage = image.images ? image.images.fallback.src : image;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
      <link rel="manifest" href="/icons/site.webmanifest" />
      <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://oddcommon.com" />
      <meta property="og:image" content={socialImage} />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="https://oddcommon.com" />
      <meta name="twitter:title" content={title} />
      {children}
    </Helmet>
  );
};
Meta.defaultProps = {
  title: 'OddCommon',
  description:
    'OddCommon is an independent design and technology agency, based in Brooklyn, New York.',
  image: 'https://oc1.oddcommon.dev/images/social.jpg',
};
export default Meta;
